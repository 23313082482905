:root {
    /* Color Setup */
}
*,
*:after,
*:before {
    box-sizing: border-box;
}
body {
    margin: 0;
    padding: 0;
}
body {
    font-size: 16px;
    font-weight: 400;
    color: #6e6f74;
    font-family: 'Rubik', sans-serif;
    position: relative;
}
a {
    text-decoration: none;
}
img {
    max-width: 100%;
}
strong {
    font-weight: 900;
}
.container {
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.grid-2 {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.grid-2 > div {
    padding-right: 30px;
  }
.grid-2 > div div:last-child {
      padding-right: 0;
    }
.grid-3 {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.grid-3 > div {
    padding-right: 30px;
  }
.grid-3 div:last-child {
    padding-right: 0;
  }
.grid-4 {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.grid-4 > div {
    padding-right: 30px;
  }
.grid-4 div:last-child {
    padding-right: 0;
  }
.grid-5 {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.grid-5 > div {
    padding-right: 30px;
  }
.grid-5 div:last-child {
    padding-right: 0;
  }
.align-center {
  text-align: center;
}
.align-left {
  text-align: left;
}
.align-right {
  text-align: right;
}
.align-justify {
  text-align: justify;
}
.header {
  background-color: #04164d;
  height: 6rem;
  width: 100%;
  position: relative;
  z-index: 999;
}
.header > .container {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
  }
.navbar {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-basis: 90%;
      -ms-flex-preferred-size: 90%;
          flex-basis: 90%;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-left: 1.5rem;
}
.brand {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.brand > h1 {
    position: absolute;
    top: -100%;
    opacity: 0;
  }
.brand img {
    margin: 1rem 1rem 1rem 0rem;
    height: 2.5rem;
  }
.nav-item-link {
  display: inline-block;
  text-transform: uppercase;
  text-align: center;
  font-weight: 400;
  line-height: 1rem;
  padding: 0 1rem;
  position: relative;
  color: #fff;
  transition: all 0.2s ease-out;
}
.nav-item-link:hover {
    color: #febb01;
  }
.nav-item-link.active {
    color: #febb01;
  }
.nav .nav-item {
    display: inline-block;
    text-transform: uppercase;
    text-align: center;
    font-weight: 400;
    line-height: 6rem;
    padding: 0 1.5rem;
    box-shadow: 0px 0px 0px transparent;
    position: relative;
    color: #fff;
    font-size: 14px;
    transition: all 0.2s ease-out;
  }
.nav .nav-item:hover {
      color: #febb01;
    }
.nav .nav-item.active {
      color: #febb01;
    }
.nav .nav-item:before {
      content: "";
      display: block;
      position: absolute;
      top: -3px;
      left: 0;
      right: 0;
      height: 3px;
      width: 100%;
      background-color: #febb01;
      -webkit-transform: translate(0, -100%);
          -ms-transform: translate(0, -100%);
              transform: translate(0, -100%);
      will-change: transform;
      transition: all 0.2s ease-out;
    }
.nav .nav-item:hover:before,
    .nav .nav-item.active:before {
      -webkit-transform: translate(0, 100%);
          -ms-transform: translate(0, 100%);
              transform: translate(0, 100%);
      will-change: transform;
    }
/* &:last-child {
      padding-right: 0;
    } */
.nav .nav-item > span {
      padding-right: 5px;
    }
.nav-alt {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}
.nav-alt .nav-item {
    font-family: 'Muli', sans-serif;
    font-size: 1.25rem;
    color: #fff;
    line-height: 120%;
    font-weight: 600;
    padding: 1rem 0;
    transition: all 0.2s ease-out;
  }
.nav-alt .nav-item:hover {
      color: #febb01;
    }
.nav-alt .nav-item.active {
      color: #febb01;
    }
.nav-alt > div {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
  }
.nav-button {
  display: none;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
  width: 3.5rem;
  height: 3.5rem;
  padding: 1rem;
  margin-right: -1rem;
  border-radius: 5px;
}
.nav-button span {
    width: 100%;
    height: 3px;
    background: #fff;
    display: block;
    transition: all 0.2s ease-out;
  }
.nav-button.active span:nth-child(1) {
    -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transform-origin: 90% 150%;
        -ms-transform-origin: 90% 150%;
            transform-origin: 90% 150%;
  }
.nav-button.active span:nth-child(2) {
    -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transform-origin: 52% 80%;
        -ms-transform-origin: 52% 80%;
            transform-origin: 52% 80%;
  }
.nav-button.active span:nth-child(3) {
    opacity: 0;
  }
.nav-button:active {
    background-color: rgba(255, 255, 255, 0.08);
  }
.mobile-nav {
  background-color: #04164d;
  position: absolute;
  z-index: 99;
  top: 4.5rem;
  width: 100%;
  height: calc(100vh - 3.5rem);
  text-align: center;
  transition: all 0.2s ease-out;
  -webkit-transform: translateY(-120%);
      -ms-transform: translateY(-120%);
          transform: translateY(-120%);
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 3rem;
}
.mobile-nav.active {
    -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
            transform: translateY(0);
  }
/* Dropdown Button */
.dropbtn {
  background-color: #04164d;
  color: white;
  padding: 0;
  font-size: 16px;
  border: none;
  cursor: pointer;
  font-family: "Muli", sans-serif;
  color: #fff;
  line-height: 120%;
}
.dropbtn:focus {
    outline: none;
  }
.dropbtn-arrow {
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  height: 10px;
  width: 10px;
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
  margin-left: 10px;
  margin-top: 4px;
}
/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}
/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: block;
  position: absolute;
  background-color: #04164d;
  min-width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 70px;
  line-height: initial;
}
/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}
/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {
  display: block;
}
.lang-container {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  text-transform: uppercase;
  text-align: center;
  font-weight: 400;
}
.lang {
  text-transform: uppercase;
  width: 30px;
  text-align: initial;
}
.lang-flag {
  width: 30px;
}
.dropdown-menu {
  display: none;
  position: absolute;
  background-color: #04164d;
  line-height: initial;
  margin-top: -20px;
  -webkit-transform: translate(-15px);
      -ms-transform: translate(-15px);
          transform: translate(-15px);
}
@media (max-width: 1024px) {
.dropdown-menu {
    position: static;
    margin-top: 0
}
  }
.dropdown-menu.active {
    display: block;
  }
.dropdown-menu-button {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background: transparent;
  border: none;
  color: #fff;
  font-size: 16px;
  padding: 0;

  -webkit-justify-content: space-between;

      -ms-flex-pack: justify;

          justify-content: space-between;
  padding: 10px 20px;
}
.dropdown-menu-button img {
    width: 30px;
  }
@media (max-width: 1024px) {
.dropdown-menu-button {
    margin: 0 auto
}
  }
.dropdown-menu-button-text {
  margin-right: 7px;
}
.dropdown-menu-button-flag {
  width: 30px;
}
.title-1 {
    font-family: 'Muli', sans-serif;
    font-size: 4rem;
    color: #04164d;
    line-height: 120%;
    font-weight: 900;
}
.title-1-light {
    font-family: 'Muli', sans-serif;
    font-size: 4rem;
    color: #04164d;
    line-height: 120%;
    font-weight: 900;
}
.title-2 {
    font-family: 'Muli', sans-serif;
    font-size: 2rem;
    color: #04164d;
    line-height: 120%;
    font-weight: 700;
}
.title-2-light {
    font-family: 'Muli', sans-serif;
    font-size: 2rem;
    color: #04164d;
    line-height: 120%;
    font-weight: 700;
}
.title-3 {
    font-family: 'Muli', sans-serif;
    font-size: 1.5rem;
    color: #04164d;
    line-height: 120%;
    font-weight: 700;
}
.card-title {
    font-family: 'Muli', sans-serif;
    font-size: 1.5rem;
    color: #04164d;
    line-height: 120%;
    font-weight: 700;
}
.title-4 {
    font-family: 'Muli', sans-serif;
    font-size: 1.25rem;
    color: #04164d;
    line-height: 120%;
    font-weight: 600;
}
.title-4-light {
    font-family: 'Muli', sans-serif;
    font-size: 1.25rem;
    color: #04164d;
    line-height: 120%;
    font-weight: 600;
}
.title-5 {
    font-family: 'Muli', sans-serif;
    font-size: 1rem;
    color: #04164d;
    line-height: 120%;
    font-weight: 900;
}
.title-5-light {
    font-family: 'Muli', sans-serif;
    font-size: 1rem;
    color: #04164d;
    line-height: 120%;
    font-weight: 900;
}
.text {
    font-family: 'Rubik', sans-serif;
    font-size: 1rem;
    color: #6e6f74;
    line-height: 120%;
    font-weight: 300;
}
.text-light {
    font-family: 'Rubik', sans-serif;
    font-size: 1rem;
    color: #6e6f74;
    line-height: 120%;
    font-weight: 300;
}
.title-1-light {
    color: #ebecf1;
}
.title-2-light {
    color: #ebecf1;
}
.title-4-light {
    color: #ebecf1;
}
.title-5-light {
    color: #ebecf1;
}
.text-light {
    color: #ebecf1;
}
.btn {
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 1.1rem;
  height: 3.5rem;
  border-radius: 5px;
  font-weight: 400;
  cursor: pointer;
  border: none;
  text-align: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  transition: all 0.2s ease-out;
}
.btn-primary {
  background-color: #febb01;
  color: #04164d;
}
.btn-primary:hover {
    background-color: #cc9600;
  }
.btn-secondary {
  color: #04164d;
  border: 1px solid #04164d;
  background-color: transparent;
}
.btn-secondary:hover {
    background-color: #04164d;
    color: #fff;
  }
.btn-block {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
.btn-link {
  font-size: 1rem;
  color: #04164d;
  cursor: pointer;
  box-shadow: 0 1px 0px #04164d;
  display: inline-block;
  font-weight: 400;
}
.btn-social {
  display: inline-block;
  border: 2px solid #ebecf1;
  padding: 0.65rem;
  height: 3rem;
  width: 3rem;
  border-radius: 3rem;
}
.btn-social > img {
    width: 1.5rem;
  }
.footer {
  background-color: #04164d;
  padding-top: 6rem;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}
.footer-info .andress {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
}
.sub-footer {
  margin-top: 5rem;
  border-top: 2px solid #ebecf1;
  height: 5rem;
}
.sub-footer > .container {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    height: 100%;
  }
.sub-footer .nav-item {
    padding-right: 1rem;
  }
.social-link {
  text-align: center;
}
.social-link .btn-social {
    margin-right: 2rem;
  }
.social-link .btn-social:last-child {
    margin-right: 0;
  }
.vertical-divider {
  display: inline-block;
  width: 2px;
  height: 16px;
  background-color: #fff;
  margin: -3px 1rem;
}
@media screen and (max-width: 480px) {
  .grid-3 > div {
    padding: 0;
  }
}
.row-50-50 {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.row-50-50 > .col-1 {
    padding: 6rem 0 6rem 0;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 50%;
  }
.row-50-50 > .col-2 {
    padding: 6rem 0 6rem 0;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    width: 50%;
  }
.row-50-50 .title-1,
  .row-50-50 .title-1-light {
    margin-top: 0;
    margin-bottom: 3rem;
  }
.row-50-50 .col-1-content {
    width: calc(1200px / 2);
    margin-left: 10%;
    padding-right: 10%;
  }
.row-50-50 .col-2-content {
    width: calc(1200px / 2);
    margin-right: 10%;
    padding-left: 10%;
  }
.partner-icons {
  padding-right: 0 !important;
  margin-left: 30%;
  max-width: 50%;
}
.card {
    border-radius: 5px;
    box-shadow: 0px 4px 16px 2px rgba(0,0,0,.15);
    text-align: center;
    background-color: #fff;
}
.card-image {
    background-color: #ebecf1;
    border-radius: 5px 5px 0 0;
    height: 12rem;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    background-size: cover;
}
.card-body {
    padding: 1rem;
}
.card-title {
    margin: 1rem 0;
}
.card .title-2,
.card .title-2-light {
    margin-top: -0.5rem;
    margin-bottom: 2rem;
}
.collapse.show .collapse-body {
        -webkit-transform: scaleY(1);
            -ms-transform: scaleY(1);
                transform: scaleY(1);
        height: auto;
        padding-bottom: 1rem;
    }
.collapse.show .collapse-header-icon {
        -webkit-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
                transform: rotate(180deg);
    }
.collapse.show .collapse-header-title {
        color: #04164d;
    }
.collapse-header {
    font-size: 1rem;
    color: #000000;
    border-top: 2px solid #ebecf1;
    font-family: 'Muli', sans-serif;
    line-height: 1rem;
    padding: 1rem 0;
    cursor: pointer;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.collapse-header:hover {
        color: #04164d;
    }
.collapse-header:last-child {
        border-bottom: 2px solid #ebecf1;
    }
.collapse-body {
    transition: all .2s ease;
    -webkit-transform: scaleY(0);
        -ms-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 0% 0%;
        -ms-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    will-change: transform;
    height: 0px;
}
.collapse-header-icon {
    transition: all .2s ease;
    will-change: transform;
}
.input-label {
  font-size: 1rem;
  line-height: 1rem;
  display: block;
  width: 100%;
  outline: none;
  background: transparent;
  font-family: 'Rubik', sans-serif;
  transition: all 0.2s ease-out;
}
.input {
  font-size: 1rem;
  line-height: 1rem;
  display: block;
  width: 100%;
  outline: none;
  background: transparent;
  font-family: 'Rubik', sans-serif;
  transition: all 0.2s ease-out;
}
.input-label {
  position: relative;
  margin-top: 1.5rem;
}
.input-label > span {
    position: absolute;
    will-change: transform;
    -webkit-transform: translateY(16px);
        -ms-transform: translateY(16px);
            transform: translateY(16px);
    z-index: 1;
    transition: all 0.2s ease-out;
  }
.input-label.active > span {
    -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
            transform: translateY(0);
    font-size: 0.75rem;
  }
input {
  height: 3rem;
  padding: 1rem 0 0.5rem;
  border: none;
  border-bottom: 2px solid #6e6f74;
  position: relative;
  z-index: 2;
  color: #04164d;
  -webkit-appearance: none;
          appearance: none;
  border-radius: 0;
  font-family: 'Rubik', sans-serif;
}
input:focus,
  input:hover {
    border-color: var(--color-primary);
  }
textarea.input {
  height: 3rem;
  padding: 1rem 0 0.5rem;
  border: none;
  border-bottom: 2px solid #6e6f74;
  position: relative;
  z-index: 2;
  color: #04164d;
  -webkit-appearance: none;
          appearance: none;
  border-radius: 0;
  font-family: 'Rubik', sans-serif;
}
textarea.input:focus,
  textarea.input:hover {
    border-color: var(--color-primary);
  }
textarea {
  height: 3rem;
  padding: 1rem 0 0.5rem;
  border: none;
  border-bottom: 2px solid #6e6f74;
  position: relative;
  z-index: 2;
  color: #04164d;
  -webkit-appearance: none;
          appearance: none;
  border-radius: 0;
  font-family: 'Rubik', sans-serif;
}
textarea:focus,
  textarea:hover {
    border-color: var(--color-primary);
  }
.contact-form {
  text-align: left;
}
.contact-form input[type="submit"] {
    margin-top: 1.5rem;
    width: 100%;
    display: block;
  }
.input-select {
  -webkit-appearance: none;
  border: none;
  color: #6e6f74;
  border-bottom: 2px solid;
  padding: 15px 0;
  border-radius: 0;
}
option {
  background-color: white;
}
select::-ms-expand {
  display: none;
}
@media screen and (max-width: 480px) {
  .contact-section > .row-50-50 {
    -webkit-flex-flow: column;
        -ms-flex-flow: column;
            flex-flow: column;
  }

  .contact-section > .row-50-50 > .col-1 {
    -webkit-flex-flow: column;
        -ms-flex-flow: column;
            flex-flow: column;
    margin: 0;
    padding: 0 60px;
  }

    .contact-section > .row-50-50 > .col-1 > .partner-icons {
      margin: 0;
    }

  .contact-section > .row-50-50 > .col-2 {
    height: auto;
  }

  .faq-section > .container > .grid-2 {
    -webkit-flex-flow: column;
        -ms-flex-flow: column;
            flex-flow: column;
  }

    .faq-section > .container > .grid-2 > div {
      padding: 0;
    }
}
#tns-dots {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.tns-nav-item {
    height: 12px;
    width: 12px;
    background-color: #6e6f74;
    display: inline-block;
    margin: 1rem;
    border-radius: 12px;
    outline: none;
}
.tns-nav-item.tns-nav-active {
        background-color: #febb01;
    }
.tns-item .card {
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: calc(100% - 2rem);
    margin-left: 1rem;
}
.toast {
  background: transparent;
  position: fixed;
  bottom: 15px;
  left: 15px;
  background: #333;
  color: #fff;
  border: none;
  padding: 20px;
  font-size: 16px;
  border-radius: 5px;
  -webkit-transform: translateY(60px);
      -ms-transform: translateY(60px);
          transform: translateY(60px);
  opacity: 0;
  transition: 0.3s;
}
.toast.active {
  opacity: 1;
  -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
          transform: translateY(0);
}
.loader {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.loader div {
  height: 15px;
  width: 5px;
  margin: 2px;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
          animation: sk-stretchdelay 1.2s infinite ease-in-out;
  border-radius: 3px;
  background: #04164d;
}
.loader div:nth-child(1) {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}
.loader div:nth-child(2) {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}
.loader div:nth-child(3) {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}
@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
            transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
            transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
#home .hero > .col-2 {
    background-color: #6e6f74;
    background-size: cover;
    background-image: url(/img/helicopter_traveler_img.jpg);
    background-position: center center;
  }
#home .hero-cta {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#home .hero-cta .btn-link {
    margin-top: 1.5rem;
  }
#home .hero-cta .btn-primary,
  #home .hero-cta .btn-secondary {
    margin-top: 1.5rem;
  }
#home .hero-cta > div {
    padding-right: 20px;
  }
#home .hero-cta > div:last-child {
    padding-right: 0;
  }
#home .hero-cta > div > p {
    min-height: 60px;
  }
.hero-column {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#travel .hero {
    background-color: #04164d;
  }
#travel .border-div {
    border: 2px solid #ebecf1;
    width: 50%;
    margin: 0 auto;
  }
#travel .hero.row-50-50 .col-1-content {
      padding-right: 0;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
    }
#travel .hero.row-50-50 .col-2-content {
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-justify-content: center;
          -ms-flex-pack: center;
              justify-content: center;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
    }
#travel .hero .col-2-content {
      position: relative;
    }
#travel .hero > .col-1 {
      padding: 8rem 0 9rem 0;
    }
#travel .mobile-img {
    width: 80%;
  }
#travel .how-section {
    /* border-bottom: 4px solid rgba(0, 0, 0, 0.15); */
  }
#travel .why-section {
    background-color: #04164d;
  }
#travel .why-section .title-2 {
      color: #ebecf1;
    }
#travel .why-section .title-4 {
      color: #ebecf1;
      margin-top: 2rem;
    }
#travel .why-section .text {
      color: #ebecf1;
    }
#travel .offers-section,
  #travel .how-section,
  #travel .why-section {
    padding: 5rem 0;
    text-align: center;
  }
#travel .offers-section .title-2, #travel .how-section .title-2, #travel .why-section .title-2 {
      margin-top: 0;
      margin-bottom: 4rem;
      text-align: center;
    }
#travel .faq-section {
    padding: 5rem 0;
    background-color: #ebecf1;
  }
#travel .faq-section .title-2 {
      font-weight: 900;
      margin-bottom: 4rem;
    }
#travel #destination-img {
    background-image: url(/img/destinationImg.jpg);
  }
#travel #scenic-img {
    background-image: url(/img/scenicImg.jpg);
  }
#travel #skidrop-img {
    background-image: url(/img/skidropImg.jpg);
  }
#travel .cta-section .col-1 {
      background-image: url(/img/southafricanmen_mobile.jpg);
      background-size: cover;
    }
#travel .cta-section .btn-primary {
      margin-top: 2rem;
    }
#travel .faq-section {
    background-image: url(/img/worldmap_bg.jpg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
#travel .what-is-section {
    padding: 3rem 10rem 5rem;
    text-align: center;
    margin: auto;
  }
@media screen and (max-width: 1024px) {
    #travel .what-is-section {
      padding: 5rem 0;
      text-align: center;
      width: 95%;
      margin: auto;
    }

    #travel .what-is-section-card {
      width: 300px;
    }
  }
#travel .what-is-section-cards {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 50px 0 0;
    -webkit-justify-content: space-around;
        -ms-flex-pack: distribute;
            justify-content: space-around;
  }
#travel .what-is-section-card {
    width: 355px;
    padding: 0;
    border-radius: 10px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    justify-self: center;
    border-radius: 3%;
    overflow: hidden;
    box-shadow: 3px 3px 9px 3px #ebecf1;
  }
#travel .what-is-section-card > img {
      height: 200px;
    }
@media screen and (max-width: 480px) {
    #travel .what-is-section-cards {
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-flow: column;
          -ms-flex-flow: column;
              flex-flow: column;
      margin: auto;
    }
    #travel .what-is-section-card {
      width: 350px;
      margin: 10px auto 20px;
    }
  }
#travel .what-is-section-card {
    border-radius: 10px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    justify-self: center;
    border-radius: 3%;
    overflow: hidden;
    box-shadow: 3px 3px 32px 3px #ebecf1;
    width: 300px;
  }
#travel .what-is-section-card > img {
      height: 200px;
    }
#travel .image-heli {
    height: 200px;
    background-image: url(/img/helicopter_sunset_img.jpg);
    background-repeat: no-repeat;
    background-position: top;
  }
#travel .image-island {
    height: 200px;
    background-image: url(/img/grand_opening_img.jpg);
    background-repeat: no-repeat;
    background-position: top;
  }
#travel .image-ski {
    height: 200px;
    background-image: url(/img/ski_people_img.jpg);
    background-repeat: no-repeat;
    background-position: top;
  }
#travel .card-title {
    font-family: 'Muli', sans-serif;
    font-size: 1.5rem;
    color: #04164d;
    margin: 20px auto 0;
  }
#travel .card-text {
    font-family: 'Rubik', sans-serif;
    font-size: 1rem;
    color: #6e6f74;
    margin: 20px;
  }
#partner .hero .col-1 {
      padding: 9rem 0 9rem 0;
    }
#partner .hero .col-2 {
      background-image: url(/img/crew_member_img.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      box-shadow: 0px 8px 20px 2px rgba(0, 0, 0, 0.18);
    }
#partner .why-provider-section {
    padding: 5rem 0;
    text-align: center;
  }
#partner .why-provider-section > .title-2 {
      margin-top: 0;
      margin-bottom: 4rem;
    }
#partner .easy-section .row-50-50, #partner .management-section .row-50-50, #partner .revenues-section .row-50-50 {
      grid-template-rows: 400px;
      background-color: #ebecf1;
    }
#partner .easy-section .col-1 {
      background-image: url(/img/findheli_system_img.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
#partner .management-section .col-2 {
      background-image: url(/img/heli-pilot.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
#partner .revenues-section .col-1 {
      background-image: url(/img/Swift-Copters-Courchevel.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
#partner .faq-section {
    padding: 5rem 0;
    background-color: #ebecf1;
    background-image: url(/img/worldmap_bg.jpg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
#partner .faq-section .title-2 {
      font-weight: 900;
      margin-bottom: 4rem;
    }
.contact-section {
  padding: 0;
  background-color: #04164d;
}
@media (min-width: 768px) {
.contact-section {
    padding: 40px
}
  }
.contact-section .card {
    text-align: left;
    padding: 1.5rem;
  }
.contact-section .title-2-light {
    margin-bottom: 0;
  }
.contact-section .contact-steps {
    margin-top: 4rem;
  }
.contact-steps > div {
  position: relative;
}
.contact-steps > div:after {
    content: "";
    background-image: url(/img/steps-divider.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 65%;
    height: 1.5rem;
    width: 1.5rem;
    display: block;
    position: absolute;
    top: 14%;
    right: 5%;
  }
.contact-steps > div:last-child:after {
    display: none;
  }
.partner-last-input {
  margin-bottom: 30px;
}
#contact .card {
    margin-top: 3rem;
    text-align: left;
    padding: 1.5rem;
  }
#contact .contact-form input[type="submit"] {
      margin-top: 1.5rem;
      width: auto;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      margin-left: auto;
    }
#contact .hero .col-2 {
    background-image: url(/img/contact-bg.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
@media screen and (max-width: 480px) {
  .contact-form > .grid-2 {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: column;
        -ms-flex-flow: column;
            flex-flow: column;
  }
}
#about .hero > .col-2 {
      background-size: cover;
      background-image: url(/img/connected_hand_img.png);
      background-position: center center;
      box-shadow: 3px 3px 9px 3px #ebecf1;
    }
#about .what-is-section {
    padding: 3rem 10rem 5rem;
    text-align: center;
  }
@media screen and (max-width: 480px) {
    #about .hero {
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-flow: column;
          -ms-flex-flow: column;
              flex-flow: column;
    }
      #about .hero > .col-2 {
        width: 100%;
      }
  }
@media screen and (max-width: 1024px) {
    #about .what-is-section {
      padding: 5rem 0rem 5rem;
      text-align: center;
      width: 95%;
      margin: auto;
    }
  }
#about .faq-section {
    padding: 5rem 0;
    background-color: #ebecf1;
  }
#about .faq-section .title-2 {
      font-weight: 900;
      margin-bottom: 4rem;
    }
#about .faq-section {
    background-image: url(/img/worldmap_bg.jpg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
#about .what-is-section-cards {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 50px 0 0;
    -webkit-justify-content: space-around;
        -ms-flex-pack: distribute;
            justify-content: space-around;
  }
@media screen and (max-width: 1024px) {
    #about .what-is-section-card {
      width: 290px;
    }
  }
@media screen and (max-width: 480px) {
    #about .what-is-section-cards {
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-flow: column;
          -ms-flex-flow: column;
              flex-flow: column;
      margin: 50px 0 0;
    }
    #about .what-is-section-card {
      width: 300px;
      margin: 10px auto 20px;
    }
  }
#about .what-is-section-card {
    border-radius: 10px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    justify-self: center;
    border-radius: 3%;
    overflow: hidden;
    box-shadow: 3px 3px 9px 3px #ebecf1;
    width: 300px;
  }
#about .what-is-section-card > img {
      height: 200px;
    }
#about .image-nasa {
    height: 200px;
    background-image: url(/img/nasa-view.png);
    background-repeat: no-repeat;
    background-position: top;
  }
#about .image-men {
    height: 200px;
    background-image: url(/img/men-hand.png);
    background-repeat: no-repeat;
    background-position: top;
  }
#about .image-women {
    height: 200px;
    background-image: url(/img/women-heli.png);
    background-repeat: no-repeat;
    background-position: top;
  }
#about .card-title {
    font-family: 'Muli', sans-serif;
    font-size: 1.5rem;
    color: #04164d;
    margin: 20px auto 0;
  }
#about .card-text {
    font-family: 'Rubik', sans-serif;
    font-size: 1rem;
    color: #6e6f74;
    margin: 20px;
  }
#about .what-is-section .col-1 {
    background-size: cover;
    background-image: url(/img/what-is-fh.jpg);
    background-position: center center;
  }
#about .who-is-section .col-2 {
    background-size: cover;
    background-image: url("/img/fatherandson2_img .jpg");
    background-position: center center;
    box-shadow: 3px 3px 9px 3px #ebecf1;
  }
#about .who-is-section {
    background-color: #ebecf1;
  }
#about .how-work-section .col-2 {
    background-size: cover;
    background-image: url(/img/Optimized-pilot_withkid.jpg);
    background-position: center center;
    box-shadow: 3px 3px 9px 3px #ebecf1;
  }
#about .how-work-section {
    background-color: #ebecf1;
  }
#about .where-section {
    padding: 5rem 0;
    text-align: center;
  }
#about .where-section .title-2 {
      margin-top: 0;
      margin-bottom: 2rem;
    }
#about .team-section {
    padding: 5rem 0;
  }
#about .team-section > .container > .title-2 {
      text-align: center;
      margin-top: 0;
    }
#about .team-section > .container > .text {
      text-align: center;
      margin-bottom: 4rem;
    }
#about .contact-section {
    border-bottom: 1px solid #ebecf1;
  }
#about .about-us-title-container {
    width: 60%;
    margin-right: 5rem;
  }
#about .flags-container {
    margin-top: 50px;
  }
@media screen and (max-width: 480px) {
    #about .flags-container {
      margin-top: 50px;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-flow: wrap;
          -ms-flex-flow: wrap;
              flex-flow: wrap;
      -webkit-justify-content: space-around;
          -ms-flex-pack: distribute;
              justify-content: space-around;
    }

    #about .flags-container > div {
      width: 130px;
      padding: 0;
    }
  }
#about .flags-container img > {
    margin-top: 50px;
  }
#about .grid-5 > div > img {
    width: 65%;
    box-shadow: 3px 3px 9px 3px #ebecf1;
  }
#about .title {
    font-size: 24px;
  }
@media (min-width: 769px) {
#about .title {
      font-size: 56px
  }
    }
@media screen and (max-width: 1024px) {
  .navbar {
    display: none;
  }
  .header {
    height: 4.5rem;
  }
  .cta-section .col-2 {
    padding: 0;
  }
  .cta-section {
    grid-template-columns: 50% 50%;
  }
  .row-50-50 {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
    .row-50-50 > .col-1,
    .row-50-50 > .col-2 {
      padding: 2rem 0 3rem 0;
    }
    .row-50-50 .col-1-content,
    .row-50-50 .col-2-content {
      width: 90%;
      margin: 0 auto;
      padding: 0;
    }
  .nav {
    display: none;
  }
  .nav-button {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  #home .hero.row-50-50 > .col-2 {
    grid-row: 1;
    padding: 20%;
  }
      #travel .cta-section .row-50-50 {
        grid-template-columns: repeat(2, 50%);
        grid-template-rows: none;
      }
      #travel .cta-section .col-1 {
        padding: 6rem 0 6rem 0;
      }
      #travel .hero.row-50-50 {
        grid-template-columns: repeat(2, 50%);
        grid-template-rows: none;
      }
        #travel .hero.row-50-50 .col-1-content {
          padding-left: 10%;
        }
      #travel .hero .title-1-light {
        font-size: 2.5rem;
      }
    #partner .row-50-50 {
      grid-template-columns: repeat(2, 50%);
      grid-template-rows: none;
    }
    #partner .hero .title-1-light {
      font-size: 2.5rem;
    }
    #contact .row-50-50 {
      grid-template-columns: repeat(2, 50%);
      grid-template-rows: none;
    }
  /* #about {
    .what-is-section {
      padding: 3rem 0 3rem;
    }

    .where-section {
      padding: 5rem 0 2rem 0;
    }
  } */
}
@media screen and (max-width: 768px) {
  .grid-4,
  .grid-3,
  .grid-5 {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .contact-steps {
    grid-template-columns: 100%;
  }
  .contact-section .contact-steps > div:after {
    display: none;
  }
    #partner .row-50-50 {
      grid-template-columns: 100%;
      grid-template-rows: repeat(2, 1fr);
    }
    #partner .contact-section .row-50-50 {
      grid-template-columns: 100%;
      grid-template-rows: auto;
    }
      #partner .hero .col-1 {
        padding: 3rem 0 3rem 0;
      }
    #partner .management-section .col-2 {
      grid-row: 1;
    }
    #contact .row-50-50 {
      grid-template-columns: 100%;
      grid-template-rows: 2fr 1fr;
    }
}
@media screen and (max-width: 480px) {
  .who-is-section {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: column;
        -ms-flex-flow: column;
            flex-flow: column;
  }

  .who-is-section > .col-2 {
    width: 100%;
    height: 200px;
  }
  .who-is-section > .col-1 {
    width: 100%;
  }

  .how-work-section {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: column-reverse;
        -ms-flex-flow: column-reverse;
            flex-flow: column-reverse;
  }

  .how-work-section > .col-2 {
    width: 100%;
    height: 200px;
  }
  .how-work-section > .col-1 {
    width: 100%;
  }

  .hero {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: column-reverse;
        -ms-flex-flow: column-reverse;
            flex-flow: column-reverse;
  }
  .row-50-50 {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .row-50-50 > .col-1 {
    width: 100%;
  }
  .row-50-50 > .col-2 {
    width: 100%;
    height: 200px;
  }
}
@media screen and (max-width: 480px) {
  .row-50-50 .title-1,
  .row-50-50 .title-1-light {
    margin-bottom: 1.5rem;
  }
  .title-1 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }
  .title-3 {
    font-size: 1.25rem;
  }
  .title-2 {
    font-size: 1.5rem;
  }
  .hero .title-1 {
    font-size: 1.5rem;
  }

  .footer {
    padding-top: 3rem;
    text-align: center;
  }
    .footer .footer-info {
      grid-template-columns: none;
      grid-template-rows: auto auto;
      grid-gap: 5rem;
    }
  .footer-info {
    grid-row: 1;
  }
  .footer-brand {
    height: 4rem;
  }
  .sub-footer .container > p.text-light {
    text-align: center;
    width: 100%;
  }
  .sub-footer-nav {
    display: none;
  }
  .grid-4,
  .grid-2 {
    grid-template-columns: none;
    grid-template-rows: auto;
    grid-gap: 0;
  }
  .card-image {
    height: 8rem;
  }
    #home .hero-cta {
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: column;
          -ms-flex-direction: column;
              flex-direction: column;
    }
      #home .hero-cta .btn-link {
        margin-top: 0;
      }
    #travel .cta-section .row-50-50,
    #travel .hero.row-50-50 {
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-flow: column;
          -ms-flex-flow: column;
              flex-flow: column;
    }
      #travel .hero.row-50-50 .col-1-content {
        padding-left: 0;
      }
      #travel .hero .title-1-light {
        font-size: 2rem;
      }
      #travel .hero > .col-1 {
        padding: 2rem 0 1rem 0;
      }

      #travel .hero > .col-2 {
        height: auto;
      }

    #travel .grid-4 > div {
      padding: 0;
    }
      #partner .faq-section .grid-2 {
        grid-gap: 0;
      }
    #partner .contact-steps > div:after {
      display: none;
    }

  .easy-section > .row-50-50 {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: column;
        -ms-flex-flow: column;
            flex-flow: column;
  }

    .easy-section > .row-50-50 > .col-1 {
      height: 200px;
    }

  .management-section > .row-50-50 {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: column-reverse;
        -ms-flex-flow: column-reverse;
            flex-flow: column-reverse;
  }

    .management-section > .row-50-50 > .col-2 {
      height: 200px;
    }

  .revenues-section > .row-50-50 {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: column;
        -ms-flex-flow: column;
            flex-flow: column;
  }

    .revenues-section > .row-50-50 > .col-1 {
      height: 200px;
    }
    .revenues-section > .row-50-50 > .col-2 {
      height: auto;
    }
}
.tns-outer{padding:0 !important}
.tns-outer [hidden]{display:none !important}
.tns-outer [aria-controls],.tns-outer [data-action]{cursor:pointer}
.tns-slider{transition:all 0s}
.tns-slider>.tns-item{box-sizing:border-box}
.tns-horizontal.tns-subpixel{white-space:nowrap}
.tns-horizontal.tns-subpixel>.tns-item{display:inline-block;vertical-align:top;white-space:normal}
.tns-horizontal.tns-no-subpixel:after{content:'';display:table;clear:both}
.tns-horizontal.tns-no-subpixel>.tns-item{float:left}
.tns-horizontal.tns-carousel.tns-no-subpixel>.tns-item{margin-right:-100%}
.tns-no-calc{position:relative;left:0}
.tns-gallery{position:relative;left:0;min-height:1px}
.tns-gallery>.tns-item{position:absolute;left:-100%;transition:opacity 0s, -webkit-transform 0s;transition:transform 0s, opacity 0s;transition:transform 0s, opacity 0s, -webkit-transform 0s}
.tns-gallery>.tns-slide-active{position:relative;left:auto !important}
.tns-gallery>.tns-moving{transition:all 0.25s}
.tns-autowidth{display:inline-block}
.tns-lazy-img{transition:opacity 0.6s;opacity:0.6}
.tns-lazy-img.loaded{opacity:1}
.tns-ah{transition:height 0s}
.tns-ovh{overflow:hidden}
.tns-visually-hidden{position:absolute;left:-10000em}
.tns-transparent{opacity:0;visibility:hidden}
.tns-fadeIn{opacity:1;filter:alpha(opacity=100);z-index:0}
.tns-normal,.tns-fadeOut{opacity:0;filter:alpha(opacity=0);z-index:-1}
.tns-t-subp2{margin:0 auto;width:310px;position:relative;height:10px;overflow:hidden}
.tns-t-ct{width:2333.3333333%;width:calc(100% * 70 / 3);position:absolute;right:0}
.tns-t-ct:after{content:'';display:table;clear:both}
.tns-t-ct>div{width:1.4285714%;width:calc(100% / 70);height:10px;float:left}
.container,
.container-full {
  box-sizing: border-box;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 30px;
  padding-left: 30px;
}
.container .grid,
.container-full .grid {
  margin-right: -15px;
  margin-left: -15px;
}
@media (min-width: 576px) {
  .container {
    max-width: 576px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 992px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .container {
    max-width: 1600px;
  }
}
.grid {
  box-sizing: border-box;
  display: block;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 auto;
  position: relative;
  letter-spacing: -.31em;
  *letter-spacing: normal;
  word-spacing: -.43em;
  list-style-type: none;
}
.grid::before, .grid::after {
  box-sizing: border-box;
  letter-spacing: normal;
  word-spacing: normal;
  white-space: normal;
}
[class*="col-"] {
  box-sizing: border-box;
  letter-spacing: normal;
  word-spacing: normal;
  white-space: normal;
  position: relative;
  width: 100%;
  vertical-align: top;
  padding: 15px;
  display: inline-block;
  *display: inline;
  zoom: 1;
}
[class*="col-"]::before, [class*="col-"]::after {
  box-sizing: border-box;
  letter-spacing: normal;
  word-spacing: normal;
  white-space: normal;
}
[class*="col-"] .grid {
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  margin: -15px;
}
.col-12 {
  width: 100%;
  *width: 99.9%;
}
.col-11 {
  width: 91.66666667%;
  *width: 91.56666667%;
}
.col-10 {
  width: 83.33333333%;
  *width: 83.23333333%;
}
.col-9 {
  width: 75%;
  *width: 74.9%;
}
.col-8 {
  width: 66.66666667%;
  *width: 66.56666667%;
}
.col-7 {
  width: 58.33333333%;
  *width: 58.23333333%;
}
.col-6 {
  width: 50%;
  *width: 49.9%;
}
.col-5 {
  width: 41.66666667%;
  *width: 41.56666667%;
}
.col-4 {
  width: 33.33333333%;
  *width: 33.23333333%;
}
.col-3 {
  width: 25%;
  *width: 24.9%;
}
.col-2 {
  width: 16.66666667%;
  *width: 16.56666667%;
}
.col-1 {
  width: 8.33333333%;
  *width: 8.23333333%;
}
@media (min-width: 576px) {
  .col-xs-12 {
    width: 100%;
    *width: 99.9%;
  }
  .col-xs-11 {
    width: 91.66666667%;
    *width: 91.56666667%;
  }
  .col-xs-10 {
    width: 83.33333333%;
    *width: 83.23333333%;
  }
  .col-xs-9 {
    width: 75%;
    *width: 74.9%;
  }
  .col-xs-8 {
    width: 66.66666667%;
    *width: 66.56666667%;
  }
  .col-xs-7 {
    width: 58.33333333%;
    *width: 58.23333333%;
  }
  .col-xs-6 {
    width: 50%;
    *width: 49.9%;
  }
  .col-xs-5 {
    width: 41.66666667%;
    *width: 41.56666667%;
  }
  .col-xs-4 {
    width: 33.33333333%;
    *width: 33.23333333%;
  }
  .col-xs-3 {
    width: 25%;
    *width: 24.9%;
  }
  .col-xs-2 {
    width: 16.66666667%;
    *width: 16.56666667%;
  }
  .col-xs-1 {
    width: 8.33333333%;
    *width: 8.23333333%;
  }
}
@media (min-width: 768px) {
  .col-sm-12 {
    width: 100%;
    *width: 99.9%;
  }
  .col-sm-11 {
    width: 91.66666667%;
    *width: 91.56666667%;
  }
  .col-sm-10 {
    width: 83.33333333%;
    *width: 83.23333333%;
  }
  .col-sm-9 {
    width: 75%;
    *width: 74.9%;
  }
  .col-sm-8 {
    width: 66.66666667%;
    *width: 66.56666667%;
  }
  .col-sm-7 {
    width: 58.33333333%;
    *width: 58.23333333%;
  }
  .col-sm-6 {
    width: 50%;
    *width: 49.9%;
  }
  .col-sm-5 {
    width: 41.66666667%;
    *width: 41.56666667%;
  }
  .col-sm-4 {
    width: 33.33333333%;
    *width: 33.23333333%;
  }
  .col-sm-3 {
    width: 25%;
    *width: 24.9%;
  }
  .col-sm-2 {
    width: 16.66666667%;
    *width: 16.56666667%;
  }
  .col-sm-1 {
    width: 8.33333333%;
    *width: 8.23333333%;
  }
}
@media (min-width: 992px) {
  .col-md-12 {
    width: 100%;
    *width: 99.9%;
  }
  .col-md-11 {
    width: 91.66666667%;
    *width: 91.56666667%;
  }
  .col-md-10 {
    width: 83.33333333%;
    *width: 83.23333333%;
  }
  .col-md-9 {
    width: 75%;
    *width: 74.9%;
  }
  .col-md-8 {
    width: 66.66666667%;
    *width: 66.56666667%;
  }
  .col-md-7 {
    width: 58.33333333%;
    *width: 58.23333333%;
  }
  .col-md-6 {
    width: 50%;
    *width: 49.9%;
  }
  .col-md-5 {
    width: 41.66666667%;
    *width: 41.56666667%;
  }
  .col-md-4 {
    width: 33.33333333%;
    *width: 33.23333333%;
  }
  .col-md-3 {
    width: 25%;
    *width: 24.9%;
  }
  .col-md-2 {
    width: 16.66666667%;
    *width: 16.56666667%;
  }
  .col-md-1 {
    width: 8.33333333%;
    *width: 8.23333333%;
  }
}
@media (min-width: 1200px) {
  .col-lg-12 {
    width: 100%;
    *width: 99.9%;
  }
  .col-lg-11 {
    width: 91.66666667%;
    *width: 91.56666667%;
  }
  .col-lg-10 {
    width: 83.33333333%;
    *width: 83.23333333%;
  }
  .col-lg-9 {
    width: 75%;
    *width: 74.9%;
  }
  .col-lg-8 {
    width: 66.66666667%;
    *width: 66.56666667%;
  }
  .col-lg-7 {
    width: 58.33333333%;
    *width: 58.23333333%;
  }
  .col-lg-6 {
    width: 50%;
    *width: 49.9%;
  }
  .col-lg-5 {
    width: 41.66666667%;
    *width: 41.56666667%;
  }
  .col-lg-4 {
    width: 33.33333333%;
    *width: 33.23333333%;
  }
  .col-lg-3 {
    width: 25%;
    *width: 24.9%;
  }
  .col-lg-2 {
    width: 16.66666667%;
    *width: 16.56666667%;
  }
  .col-lg-1 {
    width: 8.33333333%;
    *width: 8.23333333%;
  }
}
@media (min-width: 1600px) {
  .col-xlg-12 {
    width: 100%;
    *width: 99.9%;
  }
  .col-xlg-11 {
    width: 91.66666667%;
    *width: 91.56666667%;
  }
  .col-xlg-10 {
    width: 83.33333333%;
    *width: 83.23333333%;
  }
  .col-xlg-9 {
    width: 75%;
    *width: 74.9%;
  }
  .col-xlg-8 {
    width: 66.66666667%;
    *width: 66.56666667%;
  }
  .col-xlg-7 {
    width: 58.33333333%;
    *width: 58.23333333%;
  }
  .col-xlg-6 {
    width: 50%;
    *width: 49.9%;
  }
  .col-xlg-5 {
    width: 41.66666667%;
    *width: 41.56666667%;
  }
  .col-xlg-4 {
    width: 33.33333333%;
    *width: 33.23333333%;
  }
  .col-xlg-3 {
    width: 25%;
    *width: 24.9%;
  }
  .col-xlg-2 {
    width: 16.66666667%;
    *width: 16.56666667%;
  }
  .col-xlg-1 {
    width: 8.33333333%;
    *width: 8.23333333%;
  }
}
.col-auto {
  -ms-flex: 1 0 auto;
  -webkit-flex: 1 0 0px;
  flex: 1 0 0px;
  width: auto;
}
@media (min-width: 576px) {
  .col-xs-auto {
    -ms-flex: 1 0 auto;
    -webkit-flex: 1 0 0px;
    flex: 1 0 0px;
    width: auto;
  }
}
@media (min-width: 768px) {
  .col-sm-auto {
    -ms-flex: 1 0 auto;
    -webkit-flex: 1 0 0px;
    flex: 1 0 0px;
    width: auto;
  }
}
@media (min-width: 992px) {
  .col-md-auto {
    -ms-flex: 1 0 auto;
    -webkit-flex: 1 0 0px;
    flex: 1 0 0px;
    width: auto;
  }
}
@media (min-width: 1200px) {
  .col-lg-auto {
    -ms-flex: 1 0 auto;
    -webkit-flex: 1 0 0px;
    flex: 1 0 0px;
    width: auto;
  }
}
@media (min-width: 1600px) {
  .col-xlg-auto {
    -ms-flex: 1 0 auto;
    -webkit-flex: 1 0 0px;
    flex: 1 0 0px;
    width: auto;
  }
}
.order-12 {
  -ms-flex-order: 12;
  -webkit-order: 12;
  order: 12;
}
.order-11 {
  -ms-flex-order: 11;
  -webkit-order: 11;
  order: 11;
}
.order-10 {
  -ms-flex-order: 10;
  -webkit-order: 10;
  order: 10;
}
.order-9 {
  -ms-flex-order: 9;
  -webkit-order: 9;
  order: 9;
}
.order-8 {
  -ms-flex-order: 8;
  -webkit-order: 8;
  order: 8;
}
.order-7 {
  -ms-flex-order: 7;
  -webkit-order: 7;
  order: 7;
}
.order-6 {
  -ms-flex-order: 6;
  -webkit-order: 6;
  order: 6;
}
.order-5 {
  -ms-flex-order: 5;
  -webkit-order: 5;
  order: 5;
}
.order-4 {
  -ms-flex-order: 4;
  -webkit-order: 4;
  order: 4;
}
.order-3 {
  -ms-flex-order: 3;
  -webkit-order: 3;
  order: 3;
}
.order-2 {
  -ms-flex-order: 2;
  -webkit-order: 2;
  order: 2;
}
.order-1 {
  -ms-flex-order: 1;
  -webkit-order: 1;
  order: 1;
}
.order-0 {
  -ms-flex-order: 0;
  -webkit-order: 0;
  order: 0;
}
@media only screen and (min-width: 576px) {
  .order-xs-12 {
    -ms-flex-order: 12;
    -webkit-order: 12;
    order: 12;
  }
  .order-xs-11 {
    -ms-flex-order: 11;
    -webkit-order: 11;
    order: 11;
  }
  .order-xs-10 {
    -ms-flex-order: 10;
    -webkit-order: 10;
    order: 10;
  }
  .order-xs-9 {
    -ms-flex-order: 9;
    -webkit-order: 9;
    order: 9;
  }
  .order-xs-8 {
    -ms-flex-order: 8;
    -webkit-order: 8;
    order: 8;
  }
  .order-xs-7 {
    -ms-flex-order: 7;
    -webkit-order: 7;
    order: 7;
  }
  .order-xs-6 {
    -ms-flex-order: 6;
    -webkit-order: 6;
    order: 6;
  }
  .order-xs-5 {
    -ms-flex-order: 5;
    -webkit-order: 5;
    order: 5;
  }
  .order-xs-4 {
    -ms-flex-order: 4;
    -webkit-order: 4;
    order: 4;
  }
  .order-xs-3 {
    -ms-flex-order: 3;
    -webkit-order: 3;
    order: 3;
  }
  .order-xs-2 {
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }
  .order-xs-1 {
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }
  .order-xs-0 {
    -ms-flex-order: 0;
    -webkit-order: 0;
    order: 0;
  }
}
@media only screen and (min-width: 768px) {
  .order-sm-12 {
    -ms-flex-order: 12;
    -webkit-order: 12;
    order: 12;
  }
  .order-sm-11 {
    -ms-flex-order: 11;
    -webkit-order: 11;
    order: 11;
  }
  .order-sm-10 {
    -ms-flex-order: 10;
    -webkit-order: 10;
    order: 10;
  }
  .order-sm-9 {
    -ms-flex-order: 9;
    -webkit-order: 9;
    order: 9;
  }
  .order-sm-8 {
    -ms-flex-order: 8;
    -webkit-order: 8;
    order: 8;
  }
  .order-sm-7 {
    -ms-flex-order: 7;
    -webkit-order: 7;
    order: 7;
  }
  .order-sm-6 {
    -ms-flex-order: 6;
    -webkit-order: 6;
    order: 6;
  }
  .order-sm-5 {
    -ms-flex-order: 5;
    -webkit-order: 5;
    order: 5;
  }
  .order-sm-4 {
    -ms-flex-order: 4;
    -webkit-order: 4;
    order: 4;
  }
  .order-sm-3 {
    -ms-flex-order: 3;
    -webkit-order: 3;
    order: 3;
  }
  .order-sm-2 {
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }
  .order-sm-1 {
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }
  .order-sm-0 {
    -ms-flex-order: 0;
    -webkit-order: 0;
    order: 0;
  }
}
@media only screen and (min-width: 992px) {
  .order-md-12 {
    -ms-flex-order: 12;
    -webkit-order: 12;
    order: 12;
  }
  .order-md-11 {
    -ms-flex-order: 11;
    -webkit-order: 11;
    order: 11;
  }
  .order-md-10 {
    -ms-flex-order: 10;
    -webkit-order: 10;
    order: 10;
  }
  .order-md-9 {
    -ms-flex-order: 9;
    -webkit-order: 9;
    order: 9;
  }
  .order-md-8 {
    -ms-flex-order: 8;
    -webkit-order: 8;
    order: 8;
  }
  .order-md-7 {
    -ms-flex-order: 7;
    -webkit-order: 7;
    order: 7;
  }
  .order-md-6 {
    -ms-flex-order: 6;
    -webkit-order: 6;
    order: 6;
  }
  .order-md-5 {
    -ms-flex-order: 5;
    -webkit-order: 5;
    order: 5;
  }
  .order-md-4 {
    -ms-flex-order: 4;
    -webkit-order: 4;
    order: 4;
  }
  .order-md-3 {
    -ms-flex-order: 3;
    -webkit-order: 3;
    order: 3;
  }
  .order-md-2 {
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }
  .order-md-1 {
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }
  .order-md-0 {
    -ms-flex-order: 0;
    -webkit-order: 0;
    order: 0;
  }
}
@media only screen and (min-width: 1200px) {
  .order-lg-12 {
    -ms-flex-order: 12;
    -webkit-order: 12;
    order: 12;
  }
  .order-lg-11 {
    -ms-flex-order: 11;
    -webkit-order: 11;
    order: 11;
  }
  .order-lg-10 {
    -ms-flex-order: 10;
    -webkit-order: 10;
    order: 10;
  }
  .order-lg-9 {
    -ms-flex-order: 9;
    -webkit-order: 9;
    order: 9;
  }
  .order-lg-8 {
    -ms-flex-order: 8;
    -webkit-order: 8;
    order: 8;
  }
  .order-lg-7 {
    -ms-flex-order: 7;
    -webkit-order: 7;
    order: 7;
  }
  .order-lg-6 {
    -ms-flex-order: 6;
    -webkit-order: 6;
    order: 6;
  }
  .order-lg-5 {
    -ms-flex-order: 5;
    -webkit-order: 5;
    order: 5;
  }
  .order-lg-4 {
    -ms-flex-order: 4;
    -webkit-order: 4;
    order: 4;
  }
  .order-lg-3 {
    -ms-flex-order: 3;
    -webkit-order: 3;
    order: 3;
  }
  .order-lg-2 {
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }
  .order-lg-1 {
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }
  .order-lg-0 {
    -ms-flex-order: 0;
    -webkit-order: 0;
    order: 0;
  }
}
@media only screen and (min-width: 1600px) {
  .order-xlg-12 {
    -ms-flex-order: 12;
    -webkit-order: 12;
    order: 12;
  }
  .order-xlg-11 {
    -ms-flex-order: 11;
    -webkit-order: 11;
    order: 11;
  }
  .order-xlg-10 {
    -ms-flex-order: 10;
    -webkit-order: 10;
    order: 10;
  }
  .order-xlg-9 {
    -ms-flex-order: 9;
    -webkit-order: 9;
    order: 9;
  }
  .order-xlg-8 {
    -ms-flex-order: 8;
    -webkit-order: 8;
    order: 8;
  }
  .order-xlg-7 {
    -ms-flex-order: 7;
    -webkit-order: 7;
    order: 7;
  }
  .order-xlg-6 {
    -ms-flex-order: 6;
    -webkit-order: 6;
    order: 6;
  }
  .order-xlg-5 {
    -ms-flex-order: 5;
    -webkit-order: 5;
    order: 5;
  }
  .order-xlg-4 {
    -ms-flex-order: 4;
    -webkit-order: 4;
    order: 4;
  }
  .order-xlg-3 {
    -ms-flex-order: 3;
    -webkit-order: 3;
    order: 3;
  }
  .order-xlg-2 {
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }
  .order-xlg-1 {
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }
  .order-xlg-0 {
    -ms-flex-order: 0;
    -webkit-order: 0;
    order: 0;
  }
}
.offset-11 {
  margin-left: 91.66666667%;
  *margin-left: 91.56666667%;
}
.offset-10 {
  margin-left: 83.33333333%;
  *margin-left: 83.23333333%;
}
.offset-9 {
  margin-left: 75%;
  *margin-left: 74.9%;
}
.offset-8 {
  margin-left: 66.66666667%;
  *margin-left: 66.56666667%;
}
.offset-7 {
  margin-left: 58.33333333%;
  *margin-left: 58.23333333%;
}
.offset-6 {
  margin-left: 50%;
  *margin-left: 49.9%;
}
.offset-5 {
  margin-left: 41.66666667%;
  *margin-left: 41.56666667%;
}
.offset-4 {
  margin-left: 33.33333333%;
  *margin-left: 33.23333333%;
}
.offset-3 {
  margin-left: 25%;
  *margin-left: 24.9%;
}
.offset-2 {
  margin-left: 16.66666667%;
  *margin-left: 16.56666667%;
}
.offset-1 {
  margin-left: 8.33333333%;
  *margin-left: 8.23333333%;
}
@media only screen and (min-width: 576px) {
  .offset-xs-11 {
    margin-left: 91.66666667%;
    *margin-left: 91.56666667%;
  }
  .offset-xs-10 {
    margin-left: 83.33333333%;
    *margin-left: 83.23333333%;
  }
  .offset-xs-9 {
    margin-left: 75%;
    *margin-left: 74.9%;
  }
  .offset-xs-8 {
    margin-left: 66.66666667%;
    *margin-left: 66.56666667%;
  }
  .offset-xs-7 {
    margin-left: 58.33333333%;
    *margin-left: 58.23333333%;
  }
  .offset-xs-6 {
    margin-left: 50%;
    *margin-left: 49.9%;
  }
  .offset-xs-5 {
    margin-left: 41.66666667%;
    *margin-left: 41.56666667%;
  }
  .offset-xs-4 {
    margin-left: 33.33333333%;
    *margin-left: 33.23333333%;
  }
  .offset-xs-3 {
    margin-left: 25%;
    *margin-left: 24.9%;
  }
  .offset-xs-2 {
    margin-left: 16.66666667%;
    *margin-left: 16.56666667%;
  }
  .offset-xs-1 {
    margin-left: 8.33333333%;
    *margin-left: 8.23333333%;
  }
  .offset-xs-0 {
    margin-left: 0;
    *margin-left: -0.1%;
  }
}
@media only screen and (min-width: 768px) {
  .offset-sm-11 {
    margin-left: 91.66666667%;
    *margin-left: 91.56666667%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
    *margin-left: 83.23333333%;
  }
  .offset-sm-9 {
    margin-left: 75%;
    *margin-left: 74.9%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
    *margin-left: 66.56666667%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
    *margin-left: 58.23333333%;
  }
  .offset-sm-6 {
    margin-left: 50%;
    *margin-left: 49.9%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
    *margin-left: 41.56666667%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
    *margin-left: 33.23333333%;
  }
  .offset-sm-3 {
    margin-left: 25%;
    *margin-left: 24.9%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
    *margin-left: 16.56666667%;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
    *margin-left: 8.23333333%;
  }
  .offset-sm-0 {
    margin-left: 0;
    *margin-left: -0.1%;
  }
}
@media only screen and (min-width: 992px) {
  .offset-md-11 {
    margin-left: 91.66666667%;
    *margin-left: 91.56666667%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
    *margin-left: 83.23333333%;
  }
  .offset-md-9 {
    margin-left: 75%;
    *margin-left: 74.9%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
    *margin-left: 66.56666667%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
    *margin-left: 58.23333333%;
  }
  .offset-md-6 {
    margin-left: 50%;
    *margin-left: 49.9%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
    *margin-left: 41.56666667%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
    *margin-left: 33.23333333%;
  }
  .offset-md-3 {
    margin-left: 25%;
    *margin-left: 24.9%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
    *margin-left: 16.56666667%;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
    *margin-left: 8.23333333%;
  }
  .offset-md-0 {
    margin-left: 0;
    *margin-left: -0.1%;
  }
}
@media only screen and (min-width: 1200px) {
  .offset-lg-11 {
    margin-left: 91.66666667%;
    *margin-left: 91.56666667%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
    *margin-left: 83.23333333%;
  }
  .offset-lg-9 {
    margin-left: 75%;
    *margin-left: 74.9%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
    *margin-left: 66.56666667%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
    *margin-left: 58.23333333%;
  }
  .offset-lg-6 {
    margin-left: 50%;
    *margin-left: 49.9%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
    *margin-left: 41.56666667%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
    *margin-left: 33.23333333%;
  }
  .offset-lg-3 {
    margin-left: 25%;
    *margin-left: 24.9%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
    *margin-left: 16.56666667%;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
    *margin-left: 8.23333333%;
  }
  .offset-lg-0 {
    margin-left: 0;
    *margin-left: -0.1%;
  }
}
@media only screen and (min-width: 1600px) {
  .offset-xlg-11 {
    margin-left: 91.66666667%;
    *margin-left: 91.56666667%;
  }
  .offset-xlg-10 {
    margin-left: 83.33333333%;
    *margin-left: 83.23333333%;
  }
  .offset-xlg-9 {
    margin-left: 75%;
    *margin-left: 74.9%;
  }
  .offset-xlg-8 {
    margin-left: 66.66666667%;
    *margin-left: 66.56666667%;
  }
  .offset-xlg-7 {
    margin-left: 58.33333333%;
    *margin-left: 58.23333333%;
  }
  .offset-xlg-6 {
    margin-left: 50%;
    *margin-left: 49.9%;
  }
  .offset-xlg-5 {
    margin-left: 41.66666667%;
    *margin-left: 41.56666667%;
  }
  .offset-xlg-4 {
    margin-left: 33.33333333%;
    *margin-left: 33.23333333%;
  }
  .offset-xlg-3 {
    margin-left: 25%;
    *margin-left: 24.9%;
  }
  .offset-xlg-2 {
    margin-left: 16.66666667%;
    *margin-left: 16.56666667%;
  }
  .offset-xlg-1 {
    margin-left: 8.33333333%;
    *margin-left: 8.23333333%;
  }
  .offset-xlg-0 {
    margin-left: 0;
    *margin-left: -0.1%;
  }
}
.wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.no-wrap {
  -ms-flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.no-wrap [class*="col-"] {
  -ms-flex-negative: 1;
  -webkit-flex-shrink: 1;
          flex-shrink: 1;
}
.wrap-reverse {
  -ms-flex-wrap: wrap-reverse;
  -webkit-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}
.direction-row {
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
}
.direction-row-reverse {
  -ms-flex-direction: row-reverse;
  -webkit-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.direction-column {
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.direction-column-reverse {
  -ms-flex-direction: column-reverse;
  -webkit-flex-direction: column-reverse;
  flex-direction: column-reverse;
}
.align-start {
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}
.align-end {
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}
.align-end [class*="col-"] {
  vertical-align: bottom;
}
.align-center {
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.align-center [class*="col-"] {
  vertical-align: middle;
}
.align-baseline {
  -ms-flex-align: baseline;
  -webkit-align-items: baseline;
  align-items: baseline;
}
.align-content-start {
  -ms-flex-line-pack: start;
  -webkit-align-content: flex-start;
          align-content: flex-start;
}
.align-content-end {
  -ms-flex-line-pack: end;
  -webkit-align-content: flex-end;
          align-content: flex-end;
}
.align-content-end [class*="col-"] {
  vertical-align: bottom;
}
.align-content-center {
  -ms-flex-line-pack: center;
  -webkit-align-content: center;
          align-content: center;
}
.align-content-space-between {
  -ms-flex-line-pack: justify;
  -webkit-align-content: space-between;
          align-content: space-between;
}
.align-content-space-around {
  -ms-flex-line-pack: distribute;
  -webkit-align-content: space-around;
          align-content: space-around;
}
.align-self-stretch {
  -ms-flex-item-align: stretch;
  -webkit-align-self: stretch;
  align-self: stretch;
}
.align-self-start {
  -ms-flex-item-align: start;
  -webkit-align-self: flex-start;
  align-self: flex-start;
}
.align-self-end {
  -ms-flex-item-align: end;
  -webkit-align-self: flex-end;
  align-self: flex-end;
  vertical-align: bottom;
}
.align-self-center {
  -ms-flex-item-align: center;
  -webkit-align-self: center;
  align-self: center;
  vertical-align: middle;
}
.align-self-baseline {
  -ms-flex-item-align: baseline;
  -webkit-align-self: baseline;
  align-self: baseline;
  vertical-align: baseline;
}
.justify-start {
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}
.justify-start.grid {
  text-align: left;
}
.justify-end {
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
.justify-end.grid {
  text-align: right;
  text-align-last: right;
}
.justify-end.grid [class*="col-"] {
  text-align: left;
  text-align: start;
  text-align-last: left;
  text-align-last: start;
}
.justify-center {
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.justify-center.grid {
  text-align: center;
  text-align-last: center;
}
.justify-center.grid [class*="col-"] {
  text-align: left;
  text-align: start;
  text-align-last: left;
  text-align-last: start;
}
.justify-space-between {
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.justify-space-between.grid {
  text-align: justify;
  text-align-last: justify;
}
.justify-space-between.grid [class*="col-"] {
  text-align: left;
  text-align: start;
  text-align-last: left;
  text-align-last: start;
}
.justify-space-around {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  justify-content: space-around;
}
.justify-space-around.grid {
  text-align: justify;
  text-align-last: justify;
}
.justify-space-around.grid [class*="col-"] {
  text-align: left;
  text-align: start;
  text-align-last: left;
  text-align-last: start;
}
.grid-bleed [class*="col-"] {
  padding: 0;
}
.col-grid {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.col-grid.direction-row {
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
}
.col-bleed {
  padding: 0;
}
.col-bleed-x {
  padding: 15px 0;
}
.col-bleed-y {
  padding: 0 15px;
}
.flex-img {
  display: block;
  -ms-flex: 0 0 auto;
  -webkit-flex: 0 0 auto;
  flex: 0 0 auto;
  max-width: 100%;
  height: auto;
  width: 100%;
  *width: auto;
}
.flex-footer {
  width: 100%;
  margin-top: auto;
  margin-bottom: 0;
}
.flex-footer > :last-child {
  margin-bottom: 0;
}
@media (max-width: 575px) {
  .hidden-xxs {
    display: none;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .hidden-xs {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none;
  }
}
@media (min-width: 1200px) and (max-width: 1599px) {
  .hidden-lg {
    display: none;
  }
}
@media (min-width: 1600px) {
  .hidden-xlg {
    display: none;
  }
}
